<template>
  <div class="main-menu">
    <nav class="menu-grid">
      <menu-item v-for="(item, i) in menuItems"
        :key="`aside-item-${i}`"
        :itemProps="item"
      />
    </nav>
    <div class="bottom-menu__wrapper">
      <nav class="bottom-menu">
        <button
          class="menu-button"
          @click="changeLocale"
        >
          <div class="icon__wrapper">
            <strong style="font-size: 18px;">{{ langIcon }}</strong>
          </div>
          <span>{{ $t('mainMenu.lang') }}</span>
        </button>
        <button
          v-if="updatePwaExist"
          @click="handleUpdatePwa"
          class="menu-button"
        >
          <div class="icon__wrapper">
            <i class="exu-refresh-cw-03"/>
          </div>
          <span>{{ $t('mainMenu.update') }}</span>
        </button>
        <div
          v-else
          class="app_version"
        >
          v 1.4.4
        </div>
        <router-link :to="{ name: 'userProfile' }">
            <div class="icon__wrapper">
              <i class="exu-user"></i>
            </div>
            <span>{{ $t('mainMenu.profile') }}</span>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import menuItem from '@/components/shared/asideMenu/asideMenuItem.vue';

export default {
  name: 'gridMenu',
  components: { menuItem },
  props: {
    // menuItems: {
    //   type: Array,
    //   default: () => [],
    // },
    locale: {
      type: String,
      default: 'ru',
    },
    updatePwaExist: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    langIcon() {
      return this.locale === 'ru' ? 'Eng' : 'Рус';
    },
    newNotification() {
      return this.$store.getters.getNewNotificationCount < 100 ? this.$store.getters.getNewNotificationCount : '99+';
    },
    newChatUnread() {
      return this.$store.getters.getUnreadChatCount < 100 ? this.$store.getters.getUnreadChatCount : '99+';
    },
    menuItems() {
      return [
        {
          moduleName: 'about',
          iconName: 'exu-home',
          anchor: this.$t('mainMenu.about'),
          isDisabled: false,
        },
        {
          moduleName: 'staticPage',
          alias: 'regulations',
          iconName: 'exu-document',
          anchor: this.$t('mainMenu.regulations'),
        },
        {
          moduleName: 'staticPage',
          alias: 'faq',
          iconName: 'exu-doc-question-1',
          anchor: this.$t('mainMenu.faq'),
        },
        {
          moduleName: 'program',
          iconName: 'exu-calendare',
          anchor: this.$t('mainMenu.program'),
        },
        {
          moduleName: 'speakers',
          iconName: 'exu-mic',
          anchor: this.$t('mainMenu.speakers'),
        },
        {
          moduleName: 'staticPage',
          alias: 'faq',
          hash: '#faq-speaker-question',
          iconName: 'exu-question',
          anchor: this.$t('mainMenu.faq-question'),
        },
        {
          moduleName: 'participants',
          iconName: 'exu-users',
          anchor: this.$t('mainMenu.participants'),
        },
        {
          moduleName: 'partners',
          iconName: 'exu-award-3',
          anchor: this.$t('mainMenu.partners'),
        },
        {
          moduleName: 'staticPage',
          alias: 'map',
          iconName: 'exu-map',
          anchor: this.$t('mainMenu.layout'),
        },
        {
          moduleName: 'chatView',
          iconName: 'exu-mail-2',
          bubble: this.newChatUnread,
          anchor: this.$t('mainMenu.messages'),
        },
        {
          isVisible: false,
        },
        {
          moduleName: 'notifications',
          iconName: 'exu-annotation-alert',
          bubble: this.newNotification,
          anchor: this.$t('mainMenu.notification'),
        },
      ];
    },
  },
  mounted() {
  },
  methods: {
    changeLocale() {
      this.$emit('switch-locale', this.locale === 'ru' ? 'en' : 'ru');
    },
    handleUpdatePwa() {
      this.$emit('update-pwa', !this.updatePWAExist);
    },
  },
};
</script>

<style lang="scss">
.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #FFF;
  /* box-shadow: 0px -10px 10px 10px rgba(0, 0, 0, .25); */
  transition: transform .5s;
  border-left: 1px solid #e0e0e0;
  .menu-grid {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px 40px;
    padding: 15px 0;
    overflow: hidden auto;

    @media (min-width: 350px) {
      & {
        flex: 0 1 auto;
        width: 350px;
        margin: auto;
        gap: 30px 50px;
      }
    }
  }
  a, .menu-button, .menu-point {
    display: flex;
    flex: 0 1 60px;
    max-width: 60px;
    height: 78px;
    flex-direction: column;
    align-items: center;
    color: #356899;
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    border: none;
    background: transparent;

    .icon__wrapper {
      position: relative;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border: 2px solid #356899;
      border-radius: 50%;
      & > [class^="exu-"], & > [class*=" exu-"] {
        font-size: 27px;
      }
    }
    &.router-link-exact-active:not(.no-active-light), &.router-link-active:not(.no-active-light) {
      color: #356899;
      .icon__wrapper {
        background: #356899;
        & > [class^="exu-"], & > [class*=" exu-"] {
          color: #F0F0F0;
        }
      }
    }

    .bubble {
      position: absolute;
      bottom: -2px;
      right: -2px;
      display: flex;
      width: 30px;
      height: 30px;
      padding: 3px;
      font-size: .8rem;
      line-height: 1rem;
      background: #FC1010;
      border-radius: 50%;
      color: #FEFEFE;
      align-items: center;
      justify-content: center;
    }
  }
  .menu-point {
    color: #95969D;
    .icon__wrapper {
      border-color: #95969D;
    }
  }
  .bottom-menu {
    display: flex;
    justify-content: center;
    gap: 0 40px;

    @media (min-width: 350px) {
      & {
        width: 350px;
        margin: auto;
        gap: 30px 50px;
      }
    }

    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: stretch;
      height: 100px;
      width: 100%;
      border-top: 1px solid #E0E0E0;
      padding: 0;
      padding: 10px 0;
    }
  }
  .app_version {
    display: flex;
    align-items: end;
    color: #95969D;
    width: 60px;
  }
}
</style>
