<template>
  <p>{{ notificationTitle }}</p>
  <div class="systemNotification__btn-row">
    <up-button
      class="systemNotification__btn btn-denide"
      @click="handleDeclinePwa"
    >
    Cancel
    </up-button>
    <up-button
      class="systemNotification__btn btn-success"
      @click="handleInstallPwa"
    >
    {{ notificationBtnText }} <i class="exu-download-02"/>
    </up-button>
  </div>
</template>
<script>
export default {
  name: 'installPwa',
  data() {
    return {

    };
  },
  computed: {
    notificationTitle() {
      return 'Установить приложение';
    },
    notificationBtnText() {
      return 'Установить';
    },
  },
  methods: {
    handleInstallPwa() {
      this.$emit('install-pwa', true);
    },
    handleDeclinePwa() {
      this.$emit('decline-pwa', false);
    },
  },
};
</script>
<style lang="scss">
</style>
