<template>
  <p>{{ notificationTitle }}</p>
  <div class="systemNotification__btn-row">
    <up-button
      class="systemNotification__btn btn-success"
      @click="handleUpdatePwa"
    >
    {{ notificationBtnText }} <i class="exu-refresh-cw-03"/>
    </up-button>
  </div>
</template>
<script>
export default {
  name: 'updatePwa',
  props: {
    // locale: {
    //   type: String,
    //   default: 'ru',
    // },
    updatePwaExist: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    notificationTitle() {
      return this.$t('system.update_info');
    },
    notificationBtnText() {
      return this.$t('system.update');
    },
  },
  methods: {
    handleUpdatePwa() {
      this.$emit('update-pwa', !this.updatePWAExist);
    },
    handleUpdatePwa_cancel() {
      this.$emit('denide-update-pwa', !this.updatePWAExist);
    },
  },
};
</script>
<style lang="scss">
</style>
