<template>
  <div class="page__wrapper">
    <div class="container-static">
      <h1>404 page not found</h1>
    </div>
  </div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';

export default {
  name: 'PageSystem404',
  mixins: [appBaseNav],
};
</script>
