import { DateTime } from 'luxon';

export default {
  data() {
    return {
      installPwaExist: false,
      installPrompt: null,

      updatingPWA: false,
      registrationPWA: null,
      updatePWAExist: false,
      declinedUpdTs: null,
    };
  },
  created() {
    // this.installPwaExist = true;
    // this.updatePWAExist = true;
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    window.addEventListener('beforeinstallprompt', (event) => {
      console.log('beforeinstallprompt::', event);
      event.preventDefault();
      this.installPwaExist = true;
      this.installPrompt = event;
    });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!this.updatingPWA) {
        this.updatingPWA = true;
        window.location.reload();
      }
    });
  },
  computed: {
    showUpdatePwa: {
      get() {
        // const lastDenide = this.declinedUpdTs !== null ? parseInt(this.declinedUpdTs, 10) : 0;
        // eslint-disable-next-line max-len
        // const denideTimeInterval = DateTime.now().diff(DateTime.fromMillis(lastDenide), 'minutes').toObject().minutes;
        // const ts1 = DateTime.now();
        // const ts2 = DateTime.fromMillis(lastDenide);
        // console.log(DateTime.now(), DateTime.fromMillis(lastDenide));

        // eslint-disable-next-line max-len
        // return (this.declinedUpdTs === null || DateTime.now().diff(DateTime.fromMillis(lastDenide), 'minutes').toObject().minutes > 2)
        // // && this.updatePWAExist;

        // console.log('system::', this.updatePWAExist, this.declinedUpdTs, denideTimeInterval);
        return this.updatePWAExist;
      },
      // set(ts) {
      //   this.declinedUpdTs = ts;
      //   localStorage.setItem('__cancel_update__', ts);
      // },
    },
  },
  methods: {
    installPwa(userResponse) {
      if (!this.installPrompt) {
        return;
      }
      if (userResponse) {
        this.installPrompt.prompt()
          .then((result) => {
            console.log(`Install prompt was: ${result.outcome}`);
            this.installPwaExist = false;
            this.installPrompt = null;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.installPwaExist = false;
      }
    },
    updateAvailable(event) {
      this.registrationPWA = event.detail;
      this.updatePWAExist = true;
    },
    updatePWA(userResponse) {
      if (userResponse) {
        this.updatePWAExist = false;
        // this.showUpdatePwa = null;
        // eslint-disable-next-line no-useless-return
        if (!this.registrationPWA || !this.registrationPWA.waiting) return;
        this.registrationPWA.waiting.postMessage({ type: 'SKIP_WAITING' });
      } else {
        this.showUpdatePwa = DateTime.now().toMillis();
      }
    },
  },
};
