import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],

  methods: {
    getEventsList(page = 1) {
      return this.baseAxios({
        method: 'get',
        // url: `${this.$store.getters.apiBasePath}/event/page/${page}`,
        url: `${this.$store.getters.apiPath}/${this.$store.getters.getLang}/1/event/page/${page}`,
      })
        .then((data) => data)
        .catch((error) => error);
    },
  },
};
