<template>
  <div
    :class="{ 'invisible': !isVisible}"
    class="systemNotification__wrapper"
  >
    <div
      v-for="notification in listNotification"
      :key="`notification-${notification.id}`"
      class="systemNotification"
    >
      <component :is="notification.component" />
    </div>
    <div
      v-if="installPwaExist"
      class="systemNotification"
    >
      <install-pwa
        @install-pwa="installPwa(true)"
        @decline-pwa="installPwa(false)"
      />
    </div>
    <div
      v-if="updatePWAExist"
      class="systemNotification"
    >
      <update-pwa
        :updatePwaExist="updatePWAExist"
        @update-pwa="updatePWA(true)"
        @denide-update-pwa="updatePWA(false)"
      />
    </div>
  </div>
</template>
<script>
import mixinUpdatePwa from '@/components/mixins/methodsPwaUpdate';
import updatePwa from './updatePwa.vue';
import installPwa from './installPwa.vue';

export default {
  name: 'systemNotification',
  mixins: [mixinUpdatePwa],
  components: { updatePwa, installPwa },
  data() {
    return {
      listNotification: [],
    };
  },
  computed: {
    isVisible() {
      return this.listNotification > 0 || this.updatePWAExist || this.installPwaExist;
    },
  },
};
</script>
<style lang="scss">
.systemNotification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
  padding: 12px 16px;
  background: #fefefe;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 4px;

    &.invisible {
      display: none;
    }
  }

  .systemNotification__btn {
    font-size: 1.2rem;
    padding: 14px;

    &-row {
      display: flex;
      gap: 12px;
    }

    &.btn-success {
      border-color: #D76535;
      color: #D76535;
    }
    &.btn-denide {
      border-color: #95969D;
      color: #95969D;
    }
  }
}
</style>
