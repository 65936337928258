import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],

  methods: {
    getConfig(_id) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiPath}/${this.$store.state.axios.lang}/${_id}/config`,
      })
      // return axios.get(`${this.$store.state.axios.domain}/wapi/v1/ru/${id}/config`)
        .then((data) => data.results)
        .catch((error) => Promise.reject(error));
    },
    checkInbox() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        .then((data) => data?.results || {})
        .catch((error) => {
          console.error(error);
          return Promise.resolve({ notification: 0, chat: 0 });
        });
    },
    // TODO переименовать проверку LocalStorage
    checkToken() {
      const token = localStorage.getItem('token');
      const programTs = localStorage.getItem('bannerProgramTs');
      const sponsorTs = localStorage.getItem('bannerPartnerTs');
      const participantTs = localStorage.getItem('bannerParticipantTs');
      const speakerTs = localStorage.getItem('bannerSpeakerTs');
      this.$store.commit('setToken', token || null);
      this.$store.commit('setBannerProgramTs', programTs || null);
      this.$store.commit('setBannerPartnerTs', sponsorTs || null);
      this.$store.commit('setBannerParticipantTs', participantTs || null);
      this.$store.commit('setBannerSpeakerTs', speakerTs || null);
      this.$store.commit('setLang', localStorage.getItem('__exup__locale') || 'ru');
    },
  },
};
