<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <Transition name="progress">
    <progress
      v-if="isVisibleProgress"
      :value="progressVal"
      max="100"
      class="progress"
    />
  </Transition>
</template>
<script>
export default {
  name: 'progress-bar',
  data() {
    return {
      visibleProgress: false,
      timeOutID: null,
    };
  },
  computed: {
    progressVal() {
      this.hiddenProgress();
      return this.$store.getters.isProgressVal;
    },
    isVisibleProgress() {
      return this.progressVal > 0 && this.visibleProgress;
    },
  },
  mounted() {
    this.$store.commit('setProgressVal', 0);
  },
  beforeUnmount() {
    clearTimeout(this.timeOutID);
  },
  methods: {
    hiddenProgress() {
      if (this.$store.getters.isProgressVal > 99) {
        clearTimeout(this.timeOutID);
        this.timeOutID = setTimeout(() => {
          this.visibleProgress = false;
        }, 2000);
      } else {
        this.visibleProgress = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.progress {
  display: block;
  box-sizing: border-box;
  // border: solid $b #242b35;
  width: 100%;
  height: 5px;
  border-radius: 1px;
  background: #F6F6FA;
  transition: height .1s linear;

  &.progress-enter-from,
  &.progress-leave-to {
    height: 0px;
  }
  &.progress-enter-to,
  &.progress-leave-from {
    height: 5px;
  }

  &::-webkit-progress-bar { background: transparent }

  &::-webkit-progress-value {
    transition: width .5s ease-in-out;
    border-radius: 1px;
    box-shadow: inset 0 .05em .05em rgba(#fff, .35);
    background: linear-gradient(
      90deg,
      #D76535 0%,
      #da8662 50%,
      #D76535 100%,
    );
   }
  &::-moz-progress-bar {
    transition: width .5s ease-in-out;
    border-radius: 1px;
    box-shadow: inset 0 .05em .05em rgba(#fff, .35);
    background: linear-gradient(
      90deg,
      #D76535 0%,
      #da8662 50%,
      #D76535 100%,
    );
   }
}
</style>
