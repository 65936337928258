// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime, Interval } from 'luxon';

export default {
  methods: {
    timeStart(startTs) {
      return DateTime.fromMillis(startTs);
    },
    timeEnd(durationTs) {
      return this.timeStart.plus(durationTs);
    },
    timeStartEnd(timeStart, timeEnd) {
      return `${timeStart.toFormat('H:mm')} - ${timeEnd.toFormat('H:mm')}`;
    },
    dateEvent(timeStart) {
      return timeStart.setLocale('ru').toFormat('L MMMM');
    },
    dateEventLong(ts) {
      return DateTime.fromMillis(ts).setLocale('ru').toFormat('d MMMM yyyy');
    },
    timeEvent(ts) {
      return DateTime.fromMillis(ts).setLocale('ru').toFormat('HH:mm');
    },
    testDateSubtitle(startTs, endTs) {
      const start = DateTime.fromMillis(startTs);
      const end = DateTime.fromMillis(endTs);

      if (this.$store.getters.getLang === 'en') {
        return Interval.fromDateTimes(start, end).toLocaleString({
          year: 'numeric', month: 'long', day: 'numeric',
        }, { locale: 'en-GB' });
      }

      return Interval.fromDateTimes(start, end).toLocaleString({
        year: 'numeric', month: 'long', day: 'numeric',
      }, { locale: 'ru-RU' });
    },
  },
};
